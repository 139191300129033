import React from "react"
// import classNames from "classnames"

// import Image from "../components/image"
import PostListTemplate from "../components/post_list_layout"
import { graphql } from 'gatsby';

const PostListPage = function ({ data: { allMarkdownRemark: { edges } } }) {
  const list = edges.map(v => ({ ...v.node.frontmatter, slug: v.node.fields.slug }))

  return (
    <PostListTemplate list={list} />
  )
}

export const query = graphql`
query {
  allMarkdownRemark(
    sort: { fields: [frontmatter___date], order: DESC },
    filter: { frontmatter: { state: { eq: "on" }}}
  ) {
    edges {
      node {
        excerpt
        fields {
          slug
        }
        frontmatter {
          tags
          category
          date(formatString: "YYYY-MM-DD")
          title
          excerpt
        }
      }
    }
  }
}
`

export default PostListPage
