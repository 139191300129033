import React from "react"
import { Link } from "gatsby"

import Layout from "./layout"
// import Image from "../components/image"
import Tag from "./tag"
// import Svg from "./svg"
import SEO from "./seo"
// import { PostSvgs } from "./svgs"
import { catMap } from "../utils/maps"
import Category from "./category"
import Tags from "./tags"
import st from "./css/post_list_layout.module.scss"


const PostListTemplate = function ({ list, cat, tag }) {
  let title = '';
  let desc = '所有使用文档'
  if (cat) { title = catMap[cat]; desc = `【${catMap[cat]}】分类的所有文章` }
  if (tag) { title = tag; desc = `【${tag}】标签的所有文章` }

  return (
    <Layout cur='/list/' bg="#f4f4f4" showSBar>
      <SEO
        title={`${desc} - 酷课快约系统文档`}
        keywords={`${title},酷课快约系统文档,酷课快约系统教程,使用教程,帮助文档,`}
        description="酷课服务预订系统采用SaaS软件即服务的形式，服务于各类保洁、美容、家教、上门生活服务机构。我们将不断优化升级我们的软件服务，并提供详尽的技术文档教程以及专业的运营指导。"
      />

      <div className={st.wraper}>
        <div className="clearfix">
          <article className={st.main}>
            <header>
              {
                tag
                  ? (<h1><Tag tag={tag} size={2} isLink={false} >{title}</Tag></h1>)
                  : (<h1>{title || '酷课服务预订系统使用文档'}</h1>)
              }
              <p className={st.listDesc}>{desc}</p>
            </header>
            {
              list.map(({ title, slug, date, excerpt }, i) => (
                <article key={i}>
                  <header>
                    <Link to={slug} className={st.item}>
                      <h2>{title}</h2>
                    </Link>
                    <time className={st.date} dateTime={date}>发表于 {date}</time>
                  </header>
                  <p>{excerpt}...</p>
                </article>
              ))
            }
          </article>

          <div className={st.asideWrap}>
            <Category />
            <Tags />
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default PostListTemplate
